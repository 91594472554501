import api from "api/api-management";
import { tokenExpired } from "api/tokenExpired";
import { CHATBOT_SERVER, GET_CAPTCHA_PATH } from "./Constants";

const stringNullOrEmpty = (string) => {
  return !string || (string + "").trim() === "";
};

const getAllUrlParams = (url) => {
  var queryString = url ? url.split("?")[1] : window.location.search.slice(1);
  var obj = {};
  if (queryString) {
    queryString = queryString.split("#")[0];
    var arr = queryString.split("&");
    for (var i = 0; i < arr.length; i++) {
      var a = arr[i].split("=");
      var paramName = a[0];
      var paramValue = typeof a[1] === "undefined" ? true : a[1];
      paramName = paramName.toLowerCase();
      if (typeof paramValue === "string")
        paramValue = paramValue.toLowerCase();
      if (paramName.match(/\[(\d+)?\]$/)) {
        var key = paramName.replace(/\[(\d+)?\]/, "");
        if (!obj[key]) obj[key] = [];
        if (paramName.match(/\[\d+\]$/)) {
          var index = /\[(\d+)\]/.exec(paramName)[1];
          obj[key][index] = paramValue;
        } else {
          obj[key].push(paramValue);
        }
      } else {
        if (!obj[paramName]) {
          obj[paramName] = paramValue;
        } else if (obj[paramName] && typeof obj[paramName] === "string") {
          obj[paramName] = [obj[paramName]];
          obj[paramName].push(paramValue);
        } else {
          obj[paramName].push(paramValue);
        }
      }
    }
  }

  return obj;
};

const lightenColor = (hex, opacity) => {
  let r = parseInt(hex.slice(1, 3), 16);
  let g = parseInt(hex.slice(3, 5), 16);
  let b = parseInt(hex.slice(5, 7), 16);

  return `rgba(${r}, ${g}, ${b}, ${opacity})`;
};

const mobileCheck = () => {
  let check = false;
  (function (a) {
    if (
      /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
        a
      ) ||
      /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
        a.substr(0, 4)
      )
    )
      check = true;
  })(navigator.userAgent || navigator.vendor || window.opera);
  return check;
};

const removeLeadingZero = (value) => {
  let strValue = value.toString();
  let result = strValue.replace(/^0+/, '');
  return typeof value === 'number' ? Number(result) : result;
};

const postToChatBotServer = (url, data) => {
  return new Promise((resolve, reject) => {
    api
      .post(url, data)
      .then((res) => {
        if (resolve) resolve(res);
      })
      .catch((error) => {
        if (error.response?.data.code === 0) tokenExpired();
        if (reject) reject(error);
        console.error(error);
      });
  });
};

const patchToChatBotServer = (url, data) => {
  return new Promise((resolve, reject) => {
    api
      .patch(url, data)
      .then((res) => {
        if (resolve) resolve(res);
      })
      .catch((error) => {
        if (reject) reject(error);
        console.error(error);
      });
  });
};

const getToChatBotServer = (url) => {
  return new Promise((resolve, reject) => {
    api
      .get(url)
      .then((res) => {
        if (resolve) resolve(res);
      })
      .catch((error) => {
        if (error.response?.data.code === 0) tokenExpired();
        if (reject) reject(error);
        console.error(error);
      });
  });
};

const sendUserInteractionData = (data) => {
  return postToChatBotServer(
    CHATBOT_SERVER.SCENARIO_USER_RESPONSE_PATH,
    data
  );
};

const sendCreateOrderData = (data) => {
  return postToChatBotServer(
    CHATBOT_SERVER.SCENARIO_CREATE_ORDER_PATH,
    data
  );
};

const sendCountRequest = (scenarioId, data) => {
  return patchToChatBotServer(
    CHATBOT_SERVER.CONVERSION_PATH.replace(":scenario_id", scenarioId),
    data
  );
};

const getPrefectures = () => {
  return getToChatBotServer(CHATBOT_SERVER.GET_PREFECTURES_PATH);
};

const getCitiesByPrefecture = (prefectureJisCode) => {
  return getToChatBotServer(
    CHATBOT_SERVER.GET_CITIES_PATH.replace(":prefecture_jis_code", prefectureJisCode)
  );
};

const getTownsByCity = (cityJisCode) => {
  return getToChatBotServer(
    CHATBOT_SERVER.GET_TOWNS_PATH.replace(":city_jis_code", cityJisCode)
  );
};

const getScenarioPreviewData = (botId, scenarioId) => {
  return getToChatBotServer(
    CHATBOT_SERVER.GET_SCENARIO_PREVIEW_DATA_PATH
      .replace(":scenario_id", scenarioId)
      .replace(":bot_id", botId)
  );
};

const getChatBotSetting = (botId) => {
  return getToChatBotServer(
    CHATBOT_SERVER.GET_CHATBOT_SETTING_PATH.replace(":bot_id", botId)
  );
}

const sendEmailRequest = (emailId, data) => {
  return postToChatBotServer(
    CHATBOT_SERVER.SEND_EMAIL_PATH.replace(":email_id", emailId),
    data
  );
}

const getCaptcha = (size, color, charPreset) => {
  const url = GET_CAPTCHA_PATH.replace(":size", size)
    .replace(":color", color)
    .replace(":char_preset", charPreset);

  return new Promise((resolve, reject) => {
    api
      .get(url, data)
      .then((res) => {
        if (resolve) resolve(res);
      })
      .catch((error) => {
        if (reject) reject(error);
        console.error(error);
      });
  });
};

const sleep = (ms) => {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export {
  stringNullOrEmpty, getAllUrlParams, lightenColor,
  mobileCheck, removeLeadingZero, sendUserInteractionData,
  sendCreateOrderData, sendCountRequest,
  getCitiesByPrefecture, getTownsByCity, getPrefectures,
  getScenarioPreviewData, getChatBotSetting, sendEmailRequest,
  sleep, getCaptcha,
};
